import * as React from 'react';

import { graphql } from 'gatsby';

import CallOut from '../components/CallOut';
import Categories from '../components/Categories';
import Container from '../components/Container';
import Layout from '../components/Layout';

import { Dough } from '../icons';

const getMessage = `<p>Sorry, this page hasn't been baked. Please try these sweet pages:</br>
  <a href="/">Home</a>, <a href="/products/cakes">Products</a>, <a href="/about">About</a>, <a href="/faq">FAQ</a>, <a href="/reviews">Reviews</a>, <a href="/contact">Contact</a>
  </p>`;

const FourOhFourPage = ({ data }) => (
  <Layout pagetitle="404" title="404 (No-Knead to Worry)">
    <Container>
      <CallOut icon={<Dough />}>{getMessage}</CallOut>
    </Container>

    <Container>
      <Categories data={data.allContentfulProduct.edges} />
    </Container>
  </Layout>
);

export default FourOhFourPage;

export const pageQuery = graphql`
  query FourOhFourQuery {
    allContentfulProduct(sort: { fields: type }) {
      edges {
        node {
          id
          type
          title
          menu
          categoryImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
